import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import "../src/assets/vendor/bootstrap/css/bootstrap.min.css";
import "../src/assets/vendor/font-awesome/css/all.min.css";
import "../src/assets/vendor/bootstrap-select/css/bootstrap-select.min.css";
import "../src/assets/css/stylesheet.css";
import reportWebVitals from "./reportWebVitals";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { CircularProgress } from "@material-ui/core";

const App = lazy(() => import("./App"));

ReactDOM.render(
  <React.StrictMode>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Suspense
        fallback={
          <>
            <CircularProgress
              size={30}
              style={{
                color: "#2B335E",
                marginTop: '25%',
                marginLeft: '50%'
              }}
            />
          </>
        }
      >
        <App />
      </Suspense>
    </MuiPickersUtilsProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
